import Vue from 'vue'
import Component from 'vue-class-component'

/**
 * Define the mixin error interface
 */
export interface Error {
  name: string
  message: string
}

/**
 * Mixin used to handle errors, generally used to handle form validation errors.
 */
@Component
export class ErrorMixin extends Vue {
  private errors: Error[] = []

  /**
   * Get the error message for a field
   *
   * @param key The key of the error to get, generally the name of the field
   * @returns the error message for the field if it exists, otherwise null
   */
  public getError(key: string): string | null {
    const error = this.errors.find((error) => error.name === key)
    return error ? error.message : null
  }

  /**
   * Get the errors
   */
  public getErrors(): Error[] {
    return this.errors
  }

  /**
   * Set the errors for the form
   *
   * @param errors errors to be accessible from the mixin
   */
  public setErrors(errors: Error[]): void {
    this.errors = errors
  }

  /**
   * Clear the errors
   */
  public clearErrors(): void {
    this.errors = []
  }
}
