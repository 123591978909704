import { axios } from '@/plugins/axios'

/**
 * Opens a file in a new tab as a blob
 * @param url url of file
 * @param type type of file
 * 
 */
export const openFile = async (url: string, type: string): Promise<void> => {
  // As window.open is blocked by safari (popup blocker) we can't use it in a async function
  // The solution is to first open a new tab and then fetch the file
  const tab = window.open()
  return axios
    .get(url, {
      responseType: 'blob',
    })
    .then((response) => {
      const blob = new Blob([response.data], { type })
      const url = window.URL.createObjectURL(blob)
      tab?.location.replace(url)
    })
}
