import CustomRessourceSearch from '@/models/ressourcesSearch/CustomRessourceSearch'
import Speciality from '@/models/entities/Speciality'
import Surgeon from '@/models/entities/Person/Surgeon'

/**
 * Custom Ressource Search for Speciality
 */
class SpecialityRessourceSearch extends CustomRessourceSearch<Speciality, Surgeon> {
   filter(): Speciality[] {
      const filtered = super.filter()

      if (!this.dependOn || this.dependOn.length !== 1 || !this.dependOn[0].selectedRessource) {
         return filtered
      }

      const doctorsSpecialities = this.dependOn[0].selectedRessource.specialities

      return filtered.filter((speciality: Speciality) => {
         return doctorsSpecialities.some((docSpec: Speciality) => {
            return docSpec.id === speciality.id
         })
      })
   }
}

export default SpecialityRessourceSearch