import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { getAuthToken } from '@/plugins/authToken'
import { plainToInstance, instanceToPlain } from 'class-transformer'
import Model from '@/models/entities/Model'

/**
 * Create an axios instance with a custom config
 */
const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PRODUCTION_API_URL : process.env.VUE_APP_BASE_URL,
})

/**
 * Define the authorization header before every request
 */
instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getAuthToken() || ''
  config.headers.Authorization = `Bearer ${token}`
  return config
})

/**
 * Expose an instance before request
 * @param config Axios config
 * @param toExpose Ressource instance to process to an exposable
 * @param StrictType Ressource type to instantiate and call Model.expose() on
 * @returns
 */
async function exposableRequest<T extends Model>(config: AxiosRequestConfig, toExpose: Model, StrictType: new () => T) {
  const modelInstance = Object.assign(new StrictType(), toExpose)
  config.data = instanceToPlain(modelInstance.expose())
  return instance.request<T>(config)
}

/**
 * Expose instances of array before request
 * @param config Axios config
 * @param toExpose Ressource instance to process to an exposable
 * @param StrictType Ressource type to instantiate and call Model.expose() on
 * @returns Axios response
 */
async function exposableArrayRequest<T extends Model>(
  config: AxiosRequestConfig,
  toExpose: Model[],
  StrictType: new () => T
) {
  config.data = toExpose.map((item) => {
    const modelInstance = Object.assign(new StrictType(), item)
    return instanceToPlain(modelInstance.expose())
  })
  return instance.request<T>(config)
}

/**
 * Perform a request and create instance(s) of Model from axios response.data
 * @param config Axios config
 * @param Model Ressource type
 * @returns Axios response
 */
// eslint-disable-next-line
async function request<T>(config: AxiosRequestConfig, Model: any): Promise<T> {
  const response = await instance.request<T>(config)
  return plainToInstance<T, AxiosResponse['data']>(Model, response.data)
}

// Cancel requests
let controller = new AbortController()

/**
 * Perform a request linked to an AbortController so it can be canceled at any time
 * @param config Axios config
 * @returns Axios response
 */
async function cancelableRequest(config: AxiosRequestConfig) {
  config.signal = controller.signal
  return instance.request(config)
}

/**
 * Reset the AbortController to reuse it
 */
function resetAbortController(): void {
  controller = new AbortController()
}

export {
  axios as rawAxios, // Use this instance to make requests with custom bearer (useful for chat API)
  instance as axios,
  controller,
  exposableRequest,
  exposableArrayRequest,
  request,
  resetAbortController,
  cancelableRequest,
}
