import { cancelableRequest } from '@/plugins/axios'
import { VuexModule, Module, Action } from 'vuex-module-decorators'

/**
 * A picture might have two version (URL or raw SVG) with the same origin URL,
 * so it can be cached in two versions
 */
type cachedPicture = {
  blob?: string
  raw?: string
}

/**
 * Module for caching pictures
 *
 * The application uses a lot of pictures, and some of them are requested multiple times. This module optimizes the
 * performance by caching the pictures in memory. The pictures are cached in two versions: as a blob and as a raw SVG.
 *
 * This module is also necessary because the pictures need authentication to be accessed (application requirement).
 * The connected user token is used to authenticate the pictures requests.
 */
@Module({ namespaced: true })
class PicturesCacheModule extends VuexModule {
  pictures: Map<string, cachedPicture> = new Map()

  @Action
  async getPicture(config: { url: string; raw: boolean; forceRefresh: boolean }): Promise<string | void> {
    const { url, raw, forceRefresh } = config

    if (!url) return

    // Check if picture is already cached in the wanted version
    const cachedPicture = this.pictures.get(url)
    if (!forceRefresh && cachedPicture) {
      if (raw && cachedPicture.raw) return cachedPicture.raw
      if (!raw && cachedPicture.blob) return cachedPicture.blob
    }

    try {
      // Request the picture in the wanted format
      const response = await cancelableRequest({
        url: url,
        method: 'get',
        responseType: raw ? 'text' : 'blob',
      })

      if (raw) {
        // Cache the picture in raw format
        this.pictures.set(url, { blob: cachedPicture?.blob, raw: response.data })
        return response.data
      }

      // Cache the picture in blob format
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const imgUrl = URL.createObjectURL(blob)
      this.pictures.set(url, { blob: imgUrl, raw: cachedPicture?.raw })

      return imgUrl
    } catch (e) {
      return '/img/avatar_placeholder.png' // Fallback image
    }
  }
}

export default PicturesCacheModule
