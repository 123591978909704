const USER_TOKEN = 'user_token'

/**
 * Get the user's token if it exists
 */
const getAuthToken = (): string | null => {
  return localStorage.getItem(USER_TOKEN)
}

/**
 * Set the user's token
 * @param token The token to set
 */
const setAuthToken = (token: string): void => {
  localStorage.setItem(USER_TOKEN, token)
}

/**
 * Delete the user's token
 */
const deleteAuthToken = (): void => {
  localStorage.removeItem(USER_TOKEN)
}

export { getAuthToken, setAuthToken, deleteAuthToken }
