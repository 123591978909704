import html2canva, { Options } from 'html2canvas'

/**
 * Convert a HTML element to a File object, as a PNG image
 * @param htmlSelector HTML element selector to be converted (e.g. '#my-element')
 * @param filename result file name
 * @returns File object if successful, null otherwise
 */
export const htmlToImageFile = async (
  htmlSelector: string,
  filename: string,
  options?: Partial<Options>
): Promise<File> => {
  return html2canva(document.querySelector(htmlSelector)!, options).then(
    (canvas) =>
      new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(null)
          } else {
            resolve(new File([blob], filename, { type: 'image/png' }))
          }
        })
      })
  )
}
