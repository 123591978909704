import Model from '@/models/entities/Model'
import { Expose } from 'class-transformer'

/**
 * Defines a medical laboratory
 * A medical laboratory is a laboratory where medical tests and analysis are performed.
 * Surgeon are often affiliated to medicals laboratories.
 */
class MedicalLaboratory extends Model {
  id?: string

  @Expose() name!: string

  @Expose() phoneNumber!: string
}

export default MedicalLaboratory
