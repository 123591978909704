import Vue from 'vue'
import Component from 'vue-class-component'
import { Route, NavigationGuardNext } from 'vue-router'
import { controller, resetAbortController } from '@/plugins/axios'

Component.registerHooks(['beforeRouteLeave'])

/**
 * Mixin that abort "cancelableRequests" pending requests on route change
 */
@Component
export class AbortMixin extends Vue {
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    controller.abort()
    resetAbortController()
    next()
  }
}
