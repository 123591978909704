import RessourceSearch from '@/models/ressourcesSearch/RessourceSearch'
import Searchable from '@/models/interfaces/Searchable'

/**
 * Wrapper for ressource searching, selection and filtering with dependency with another ressource
 */
abstract class CustomRessourceSearch<T extends Searchable, U extends Searchable> extends RessourceSearch<T> {
   protected dependOn?: RessourceSearch<U>[]

   constructor(ressourceName: string, ressources: T[] = [], search: string = '') {
      super(ressourceName, ressources, search)
   }

   /**
    * Add a dependency to another ressource
    * @param ressSearch RessourceSearch to depend on
    */
   addDependentRessource(...ressSearch: RessourceSearch<U>[]): void {
      this.dependOn = ressSearch
   }
}

export default CustomRessourceSearch
