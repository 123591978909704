
import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Tray from '@/components/Tray.vue'
import Weavy from '@weavy/dropin-js'

const chat = namespace('ChatModule')

/**
 * Responsible for rendering the chat tray.
 * It uses the Weavy Client SDK (drop-in) to render the chat tray.
 *
 * @see https://api.weavy.com/client/api/8.0/
 */
@Component({
  components: {
    Tray,
  },
})
export default class ChatTray extends Vue {
  @Prop(String) readonly userId!: string

  weavy!: typeof Weavy

  @chat.State
  isChatTrayOpen!: boolean

  @chat.Mutation
  setUnreadMessageCount!: (count: number) => void

  created() {
    this.weavy = new Weavy({
      url: process.env.VUE_APP_WEAVY_API_URL,
      tokenFactory: this.tokenFactory,
    })

    const messenger = this.weavy.app({
      id: 'chat',
      type: 'messenger',
      container: '#chat-container',
      badge: true,
    })

    messenger.on('badge', (e: Event, badge: { count: number }) => {
      this.setUnreadMessageCount(badge.count)
    })
  }

  async tokenFactory(refresh: boolean): Promise<string> {
    return await this.getChatAuthToken({
      userId: this.userId,
      refresh: refresh ?? true, // Might want to optimise this later
    })
  }

  beforeDestroy() {
    this.weavy.authentication.signOut()
  }

  @chat.Action
  toggleChatTray!: () => void

  @chat.Action
  getChatAuthToken!: (config: { userId: string; refresh: boolean }) => Promise<string>
}
