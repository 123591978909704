
import { Component, Watch } from 'vue-property-decorator'
import { User } from '@/models/entities/User'
import { namespace } from 'vuex-class'
import { mixins } from 'vue-class-component'
import { AbortMixin } from '@/mixins/AbortMixin'
import { axios } from '@/plugins/axios'
import { format, formatDistance } from 'date-fns'

Component.registerHooks(['beforeRouteEnter'])

const user = namespace('UserModule')
const users = namespace('UsersModule')

const ALLOWED_TYPES = ['change', 'malfunction'] as const;
const ALLOWED_STATUSES = ['pending', 'in-progress', 'resolved'] as const;

@Component({})
export default class Report extends mixins(AbortMixin) {
  readonly allowedTypes = ALLOWED_TYPES;
  readonly allowedStatuses = ALLOWED_STATUSES;

  showReportWithId: string | null = null;
  editReportWithId: string | null = null;

  editedData: {
    type: typeof ALLOWED_TYPES[number] | null,
    description: string,
    status: typeof ALLOWED_STATUSES[number] | null,
    assignedTo: string | null,
  } = {
    type: null,
    description: '',
    status: null,
    assignedTo: null,
  }

  newReportComment = '';

  reports: any[] = [];

  async mounted() {
    await this.getUsers();
    await this.getReports();
  }

  @Watch('showReportWithId')
  resetEditReportWithId() {
    this.editReportWithId = null;

    this.newReportComment = '';
  }

  @Watch('editReportWithId')
  setEditData() {
    if (this.editReportWithId !== this.showReportWithId) {
      this.editedData.type = null;
      this.editedData.description = '';
      this.editedData.status = null;
      this.editedData.assignedTo = null;

      return;
    }

    const reportToEdit = this.reports.find(({ id }) => this.editReportWithId === id);

    if (!reportToEdit) {
      return console.error(`Could not find the report with id: ${this.editReportWithId} in:`, this.reports);
    }

    this.editedData.type = reportToEdit.type;
    this.editedData.description = reportToEdit.description;
    this.editedData.status = reportToEdit.status;
    this.editedData.assignedTo = reportToEdit.assignedTo?.id ?? null;
  }

  async setStatus(id: string, status: typeof this.allowedStatuses[number]) {
    await axios.patch(`/api/report/${id}`, { status });

    await this.getReports()
  }

  async deleteReport(id: string) {
    if (confirm('Are you sure you want to delete this report?')) {
      await axios.delete(`/api/report/${id}`);

      await this.getReports();
    }
  }

  async updateReport() {
    await axios.patch(`/api/report/${this.editReportWithId}`, this.editedData);

    this.editReportWithId = null;

    await this.getReports();
  }

  async addComment() {
    if (!this.newReportComment) {
      return;
    }

    await axios.post(`/api/report/${this.showReportWithId}/comment`, {
      comment: this.newReportComment,
    });

    this.newReportComment = '';

    await this.getReports();
  }

  async deleteComment(id: string) {
    if (confirm('Are you sure you want to delete this comment?')) {
      await axios.delete(`/api/report/comment/${id}`);

      await this.getReports();
    }
  }

  async getReports() {
    const { data: reports } = await axios.get<any[]>('/api/report')

    this.reports = [];

    reports.map((report) => ({
      id: report.id,
      type: report.type,
      description: report.description,
      status: report.status,
      author: `${report.author.firstname} ${report.author.lastname}`,
      assignedTo: report.assignedTo ? {
        id: report.assignedTo.id,
        name: `${report.assignedTo.firstname} ${report.assignedTo.lastname}`
      } : null,
      comments: (report.comments ?? []).map((comment: any) => ({
        id: comment.id,
        body: comment.body,
        user: `${comment.user.firstname} ${comment.user.lastname}`,
        createdAt: new Date(comment.createdAt),
      })),
      logs: (report.logs ?? []).map((log: any) => ({
        id: log.id,
        body: log.body,
        user: `${log.user.firstname} ${log.user.lastname}`,
        createdAt: new Date(log.createdAt),
      }))
      .sort((a: any, b: any) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0),
      operationSheet: {
        id: report.operationSheet.id,
        name: report.operationSheet.name,
        surgeon: {
          id: report.operationSheet.surgeon.id,
          name: `${report.operationSheet.surgeon.firstname} ${report.operationSheet.surgeon.lastname}`,
        }
      },
      surgeryObject: {
        id: report.surgeryObject.id,
        name: report.surgeryObject.name,
      },
      createdAt: new Date(report.createdAt),
    })).forEach((report) => this.reports.push(report))
  }

  formatDate(date: Date) {
    return formatDistance(date, new Date(), { addSuffix: true })
  }

  formatDisplayDate(date: Date) {
    return format(date, 'd LLL yyyy')
  }

  @user.State
  connectedUser!: User;

  @user.Getter
  isUserEditor!: boolean

  @users.Action
  getUsers!: () => Promise<any>

  @users.Getter
  allUsers!: User[]
}
