/**
 * This file is the entry point for the router.
 * It is responsible for registering the routes and middlewares.
 *
 * Vue Router is the official router for Vue.js
 * @see https://v3.router.vuejs.org/
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import authMiddleware from '@/router/middleware/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Registering middlewares
router.beforeEach(authMiddleware)

export default router
