
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CancelIcon } from '@/icons'

/**
 * A component that displays a flash message
 *
 * @prop {string} title The title of the message
 * @prop {string} content The content of the message
 * @prop {string} type The type of the message, can be 'success', 'warning' or 'error'
 *
 * Note: This component is best used programmatically, see src/plugins/flashMessage.ts.
 * Exemple: this.$flashSuccess('My title', 'My content')
 */
@Component({
  components: {
    CancelIcon,
  },
})
export default class FlashMessage extends Vue {
  @Prop(String) readonly title!: string

  @Prop(String) readonly content!: string

  @Prop({
    type: String,
    default: 'success',
    validator: (type: string) => ['success', 'warning', 'error'].includes(type),
  })
  readonly type!: string

  open: boolean = true

  close() {
    this.open = false
  }
}
