import SurgeryStaff, { SurgeryStaffType } from '@/models/entities/Person/SurgeryStaff'
import { request, exposableRequest, exposableArrayRequest, axios } from '@/plugins/axios'
import { config, VuexModule, Module, MutationAction, Action } from 'vuex-module-decorators'
import Speciality from '@/models/entities/Speciality'
import RessourceSearch from '@/models/ressourcesSearch/RessourceSearch'

config.rawError = true

/**
 * Module for operations on staffs
 */
@Module({ namespaced: true })
class StaffModule extends VuexModule {
  searcher: RessourceSearch<SurgeryStaff> = new RessourceSearch('staffs', [])

  selectedType!: SurgeryStaffType

  specialities!: Speciality[]

  readonly sizes = ['XS', 'S', 'M', 'L', 'XL']

  readonly sizesNumbers = ['6', '6.5', '7', '7.5', '8', '8.5']

  readonly sizesAll = [...this.sizes, ...this.sizesNumbers]

  get staffsCount(): number {
    return this.searcher.ressources ? this.searcher.ressources.length : 0
  }

  get filteredStaffs(): SurgeryStaff[] {
    const searchTerms = this.searcher.search.split(' ')
    let staffs = this.searcher.ressources

    if (this.selectedType) {
      staffs = staffs.filter((staff) => staff.type === this.selectedType)
    }

    staffs = staffs
      ? staffs.filter((staff) =>
          staff.searchableContent().some((filter) => searchTerms.some((term) => filter.toLowerCase().includes(term)))
        )
      : []

    return staffs.sort((a, b) => a.lastname.localeCompare(b.lastname))
  }

  get filteredStaffsCount(): number {
    return this.filteredStaffs.length
  }

  get letterGroupedStaffs() {
    const groups: { [key: string]: SurgeryStaff[] } = {}

    this.filteredStaffs.forEach((staff: SurgeryStaff) => {
      groups[staff.firstLetter()] ??= []
      groups[staff.firstLetter()].push(staff)
    })
    return groups
  }

  /**
   * Apply search terms to the staff searcher
   * @param terms the search terms
   */
  @MutationAction
  async applySearch(terms: { search: string; type: string }) {
    return {
      searcher: {
        ...this.searcher,
        search: terms.search.toLowerCase(),
      },
      selectedType: terms.type,
    }
  }

  @MutationAction
  async applyTypeFilter(type: SurgeryStaffType) {
    return { selectedType: type }
  }

  /**
   * Get all staffs
   * @returns the list of staffs
   */
  @MutationAction
  async getStaffs() {
    const staffs = await request<SurgeryStaff[]>(
      {
        method: 'get',
        url: '/api/staffs',
      },
      SurgeryStaff
    )

    return { searcher: new RessourceSearch('staffs', staffs) }
  }

  /**
   * Get all specialities
   * @returns the list of specialities
   */
  @MutationAction
  async getSpecialities() {
    const specialities = await request<Speciality[]>(
      {
        method: 'get',
        url: '/api/specialities',
      },
      Speciality
    )

    return { specialities: specialities.sort((a, b) => a.name.localeCompare(b.name)) }
  }

  /**
   * Create a new staff
   * @param data staff to create and its specific type
   * @returns created staff
   */
  @Action
  async createStaff<T extends SurgeryStaff>(data: { staff: SurgeryStaff; specificType: new () => T }) {
    const { staff, specificType } = data

    const response = await exposableRequest(
      {
        method: 'post',
        url: `/api/staffs/${staff.type}`,
      },
      staff,
      specificType
    )

    staff.id = response.data.id
    return staff
  }

  /**
   * Update a staff
   * @param data staff to update and its specific type
   * @returns updated staff
   */
  @Action
  async updateStaff<T extends SurgeryStaff>(data: { staff: SurgeryStaff; specificType: new () => T }) {
    const { staff, specificType } = data

    await exposableRequest(
      {
        method: 'patch',
        url: `/api/staffs/${staff.type}/${staff.id}`,
      },
      staff,
      specificType
    )

    return staff
  }

  /**
   * Update a staff picture
   * @param data staff to update and picture
   */
  @Action
  async updatePicture(data: { staff: SurgeryStaff; picture: File }): Promise<void> {
    const file = new FormData()
    file.append('image', data.picture)

    await axios.post(`/api/images/staff/${data.staff.id}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  /**
   * Create staffs from an array
   * Useful for mass import (CSV)
   * @param staffs staffs to create
   */
  @Action
  async createStaffs(staffs: SurgeryStaff[]): Promise<void> {
    await exposableArrayRequest(
      {
        method: 'post',
        url: '/api/staffs',
      },
      staffs,
      SurgeryStaff
    )
  }

  /**
   * Get staffs by type
   * @param type type of staffs to get
   * @returns the list of staffs of the given type
   */
  @Action
  async getStaffsByType(type: SurgeryStaffType): Promise<SurgeryStaff[]> {
    return await request<SurgeryStaff[]>(
      {
        method: 'get',
        url: `/api/staffs?type=${type}`,
      },
      SurgeryStaff
    )
  }
}

export default StaffModule
