import RessourceSearch from '@/models/ressourcesSearch/RessourceSearch'
import Searchable from '@/models/interfaces/Searchable'

/**
 * Manage multiple RessourceSearch
 */
class MultipleSearcher {
   searchableRessources: RessourceSearch<Searchable>[] = []

   /**
    * Apply filter on searchable ressource
    * @param ressourceName ressource name to filter
    * @returns filtered ressources
    */
   filterRessource(ressourceName: string): Searchable[] {
      const item = this.searchableRessources[this.researchGetIndex(ressourceName)]
      if (!item) return []

      return item.filter()
   }

   /**
    * Return the id of the searchable ressource
    * @param ressourceName ressource name to find
    * @returns id or -1 if not found
    */
   researchGetIndex(ressourceName: string): number {
      return this.searchableRessources.findIndex(
         (item: RessourceSearch<Searchable>) => item.ressourceName == ressourceName
      )
   }

   /**
    * Return the selected ressource
    * @param ressourceName selected ressource type name
    * @returns selected ressource
    */
   getSelectedRessource(ressourceName: string): Searchable | null {
      const ressource = this.searchableRessources[this.researchGetIndex(ressourceName)]
      return ressource ? ressource.selectedRessource : null
   }
}

export default MultipleSearcher
