
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CancelIcon } from '@/icons'

/**
 * A modal component used to display complex content
 *
 * Provides a header, content and footer slots to render content
 *
 * @prop isOpen whether the modal is open or not
 * @prop maxWidth the maximum width of the modal (using tailwind max-w-xxx, default is max-w-xl)
 */
@Component({
  components: {
    CancelIcon,
  },
})
export default class Modal extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean

  @Prop({
    type: String,
    default: 'max-w-xl',
  })
  readonly maxWidth!: string

  get modalClasses() {
    return `relative px-4 w-full md:h-auto ${this.maxWidth}`
  }

  close() {
    this.$emit('close')
  }
}
