/**
 * Import and defined the internationalization plugin.
 * The matching translation files are located in the src/locales folder.
 *
 * @see https://kazupon.github.io/vue-i18n/
 */
import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

const DEFAULT_LOCALE: string = 'en'

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || DEFAULT_LOCALE,
  silentTranslationWarn: true,
  messages: loadLocaleMessages(),
})
