import Model from '@/models/entities/Model'
import Listable from '@/models/interfaces/Listable'
import Searchable from '@/models/interfaces/Searchable'
import { Exclude, Expose } from 'class-transformer'

enum UserStatus {
  READER = 'Reader',
  EDITOR = 'Editor',
  ADMIN = 'Admin',
  INACTIVE = 'Inactive',
}

enum UserFunction {
  INSTRUMENTIST = 'Instrumentist',
  SURGEON = 'Surgeon',
  ASSISTANT = 'Assistant',
  TECHNICAL_ASSISTANT = 'TechnicalAssistant',
}
/**
 * Defines a user of the application
 */
class User extends Model implements Listable, Searchable {
  @Exclude({ toPlainOnly: true }) id!: string

  @Expose() lastname!: string

  @Expose() firstname!: string

  @Expose() email!: string

  @Expose() phone_number!: string

  @Expose() picture!: string

  @Expose() status!: UserStatus

  @Expose() function!: UserFunction

  @Expose() password!: string

  @Expose() canMarkNotificationAsRead!: boolean

  get fullname(): string {
    return `${this.lastname} ${this.firstname}`
  }

  firstLetter(): string {
    return this.lastname[0]
  }

  title(): string {
    return this.lastname
  }

  subtitle(): string {
    return this.firstname
  }

  preview(): string {
    return `/api/images/${this.picture}`
  }

  badge(): string {
    return ''
  }

  searchableContent(): string[] {
    return [this.firstname, this.lastname]
  }

  sortBy(): string {
    return this.lastname
  }
}

class ProfileEdition extends Model {
  constructor(phone_number: string, password: string) {
    super()
    this.phone_number = phone_number
    this.password = password
  }

  static fromUser(user: User): ProfileEdition {
    return new ProfileEdition(user.phone_number, user.password)
  }
}

export { User, UserStatus, ProfileEdition }
