import Model from '@/models/entities/Model'
import { Expose } from 'class-transformer'
import Searchable from '@/models/interfaces/Searchable'

/**
 * Defines a medical speciality
 */
class Speciality extends Model implements Searchable {
  @Expose() id!: string

  name!: string

  logo!: string

  color!: string

  order!: number

  searchableContent(): string[] {
    return [this.name]
  }

  sortBy(): string {
    return this.name
  }
}

export default Speciality
