import SurgeryObject, { SurgeryObjectType } from '@/models/entities/Objects/SurgeryObject'
import OperationTable from '@/models/entities/Objects/OperationTable'
import ISurgeryObject from '@/models/interfaces/sheets/ISurgeryObject'

/**
 * Defines a TPA complex
 * A Table-Patient-Accessories complex is a complex object that contains an OperationTable, a Patient and a list of accessories.
 * Accessories can be placed on the table or on the patient.
 */
class TPAComplex extends SurgeryObject {
  operationTable!: OperationTable

  patient!: ISurgeryObject

  tableAccessories: ISurgeryObject[] = []

  patientAccessories: ISurgeryObject[] = []

  synced!: boolean

  custom!: boolean

  constructor() {
    super(SurgeryObjectType.TPA_COMPLEX)
  }

  public expose(): TPAComplex {
    delete this.id
    delete this.type
    return this
  }
}

export default TPAComplex
