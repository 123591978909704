
import { Component, Prop } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import Notification from '@/models/entities/Notification'
import Button from '@/components/Button.vue'
import SimpleInput from '@/components/Forms/SimpleInput.vue'
import TextareaInput from '@/components/Forms/TextareaInput.vue'
import { namespace } from 'vuex-class'
import { mixins } from 'vue-class-component'
import { ErrorMixin } from '@/mixins/ErrorMixin'

const notification = namespace('NotificationModule')

/**
 * Modal for creating a new notification
 *
 * @prop {boolean} isOpen whether the modal is open or not
 *
 * @emits validated when the notification is created
 * @emits close when the modal is closed
 */
@Component({
  components: {
    Modal,
    Button,
    SimpleInput,
    TextareaInput,
  },
})
export default class CreateNotificationModal extends mixins(ErrorMixin) {
  @Prop(Boolean) isOpen!: boolean

  notification: Notification = new Notification()

  validation() {
    this.create(this.notification)
      .then(() => {
        this.$flashSuccess(this.$tc('defaults.success'), this.$tc('notifications.confirmation_send'))
        this.$emit('validated')
        this.$emit('close')
        this.notification = new Notification()
      })
      .catch((error) => {
        this.setErrors(error.response.data.errors)
      })
  }

  @notification.Action
  create!: (notification: Notification) => Promise<void>
}
