
import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Navbar.vue'

/**
 * Main app component
 * Entry point of the application
 */
@Component({
  components: {
    Navbar,
  },
})
export default class App extends Vue {
  get hideNavbar() {
    return this.$route.meta?.noNavbar
  }
}
