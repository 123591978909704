
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Modal from "../Modal.vue";
import { axios } from "@/plugins/axios";
import { namespace } from "vuex-class";
import SearchableDropdownInput from "./SearchableDropdownInput.vue";

const user = namespace("UserModule");
const instrumentData = namespace("InstrumentDataModule");

type Instrument = {
  id: string;
  name: string;
  speciality: string;
  location: Record<string, string>;
};

@Component({
  components: {
    Modal,
    SearchableDropdownInput,
  },
})
export default class InstrumentSearchModal extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean;

  @Watch("isOpen")
  onModalVisibilityChange() {
    this.search.instrumentName = "";
    this.search.speciality = "";

    this.isCurrentlyAddingNewInstrument = false;
    this.newInstrumentData = {
      name: "",
      speciality: "",
      location: {
        lieu: "",
        allée: "",
        code: "",
      },
    };

    this.currentlyEditingInstrument = null;
  }

  search = {
    instrumentName: "",
    speciality: "",
  };

  matchingInstruments: Instrument[] = [];
  matchingInstrumentsSpecialities: string[] = [];

  @Watch("search", { deep: true })
  async onSearchTermChanged(search: typeof this.search) {
    const { data: matchingInstruments } = await axios.get<Instrument[]>(
      `/api/instrument?name=${encodeURIComponent(
        search.instrumentName
      )}&speciality=${encodeURIComponent(search.speciality)}`
    );

    this.matchingInstruments = matchingInstruments;

    this.matchingInstrumentsSpecialities =
      typeof matchingInstruments === "string"
        ? [...this.instrumentSpecialities]
        : [
            ...new Set(
              matchingInstruments
                .map(({ speciality }) => speciality)
                .filter((speciality) => speciality)
            ),
          ].sort();
  }

  close() {
    this.$emit("close");

    this.reset();
  }

  reset() {
    this.search.instrumentName = "";
    this.search.speciality = "";
  }

  onListItemClick(instrument: Instrument) {
    this.search.instrumentName = instrument.name;
    this.search.speciality = instrument.speciality;
  }

  /**
   * Lifecycle Hooks
   */

  async mounted() {
    await this.initializeInstrumentData();

    this.matchingInstrumentsSpecialities = [...this.instrumentSpecialities];
  }

  /**
   * CRUD Instruments
   */

  isCurrentlyAddingNewInstrument = false;
  newInstrumentData = {
    name: "",
    speciality: "",
    location: {
      lieu: "",
      allée: "",
      code: "",
    },
  };

  startAddingNewInstrument() {
    this.isCurrentlyAddingNewInstrument = true;
  }

  get canAddNewInstrument() {
    return (
      this.newInstrumentData.name &&
      this.newInstrumentData.speciality &&
      (this.newInstrumentData.location.lieu ||
        this.newInstrumentData.location.allée ||
        this.newInstrumentData.location.code)
    );
  }

  async addNewInstrument() {
    const { data: newInstrument } = await axios.post(
      `/api/instrument`,
      this.newInstrumentData
    );

    this.matchingInstruments.unshift(newInstrument);

    this.newInstrumentData = {
      name: "",
      speciality: "",
      location: {
        lieu: "",
        allée: "",
        code: "",
      },
    };

    this.isCurrentlyAddingNewInstrument = false;
  }

  cancelNewInstrumentAddition() {
    this.isCurrentlyAddingNewInstrument = false;
  }

  currentlyEditingInstrument: Instrument | null = null;

  startUpdatingInstrument(instrument: Instrument) {
    this.currentlyEditingInstrument = {
      id: instrument.id,
      name: instrument.name,
      speciality: instrument.speciality,
      location: { ...instrument.location },
    };
  }

  cancelInstrumentUpdate() {
    this.currentlyEditingInstrument = null;
  }

  async updateInstrument() {
    const { data: updatedInstrument } = await axios.patch(
      `/api/instrument/${this.currentlyEditingInstrument?.id}`,
      this.currentlyEditingInstrument
    );

    const instrumentIndex = this.matchingInstruments.findIndex(
      ({ id }) => id === updatedInstrument.id
    );

    if (instrumentIndex === -1) {
      return;
    }

    this.matchingInstruments.splice(instrumentIndex, 1, updatedInstrument);

    this.currentlyEditingInstrument = null;
  }

  async deleteInstrument(instrumentId: string) {
    if (!confirm("Are you sure you want to delete this instrument?")) {
      return;
    }

    await axios.delete(`/api/instrument/${instrumentId}`);

    const instrumentIndex = this.matchingInstruments.findIndex(
      ({ id }) => id === instrumentId
    );

    if (instrumentIndex === -1) {
      return;
    }

    this.matchingInstruments.splice(instrumentIndex, 1);
  }

  @user.Getter
  isUserAdmin!: boolean;

  @instrumentData.Action
  initializeInstrumentData!: () => Promise<void>;

  @instrumentData.Getter
  instrumentSpecialities!: string[];

  @instrumentData.Getter
  instrumentLocationLieu!: string[];

  @instrumentData.Getter
  instrumentLocationAlley!: string[];
}
