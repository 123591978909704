import { UserStatus } from '@/models/entities/User'
import { RouteConfig } from 'vue-router'

/**
 * Extends the RouteMeta interface to add custom properties
 */
declare module 'vue-router' {
  interface RouteMeta {
    noNavbar?: boolean
    auth?: boolean
    allowedRoles?: UserStatus[]
  }
}

/**
 * Error object to be passed to the Error view
 */
export type RouteError = {
  code: string
  message: string
  description: string
  action: string
}

/**
 * List of frontend routes
 *
 * Routes that needs authentication are marked with the `auth` meta property
 * Routes that needs restrictions according to the user status should be specified in the `meta.allowedRoles`
 * property in combination with the `auth` meta property.
 * If `meta.allowedRoles` is not specified, the route is accessible to all authenticated users
 */
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Default',
    redirect: () => {
      return { path: '/plans' }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      noNavbar: true,
    },
  },
  {
    path: '/plans',
    name: 'Plans',
    component: () => import(/* webpackChunkName: "plans" */ '@/views/Plans.vue'),
    meta: { auth: true },
  },
  {
    path: '/instrumentation',
    name: 'Instrumentation',
    component: () => import(/* webpackChunkName: "instrumentation" */ '@/views/Instrumentation.vue'),
    meta: { auth: true },
  },
  {
    path: '/plan/:id/instrumentation-lists/create',
    name: 'CreateInstrumentationList',
    component: () => import(/* webpackChunkName: "create-instrumentation-lists" */ '@/views/Instrumentation/CreateInstrumentationList.vue'),
    meta: { auth: true },
  },
  {
    path: '/plan/:id/instrumentation-lists',
    name: 'ViewInstrumentationLists',
    component: () => import(/* webpackChunkName: "view-instrumentation-lists" */ '@/views/Instrumentation/InstrumentationList.vue'),
    meta: { auth: true },
  },
  {
    path: '/instrumentation-list/:id/update',
    name: 'UpdateInstrumentationList',
    component: () => import(/* webpackChunkName: "create-instrumentation-lists" */ '@/views/Instrumentation/UpdateInstrumentationList.vue'),
    meta: { auth: true },
  },
  {
    path: '/check-list/:id',
    name: 'UpdateCheckList',
    component: () => import(/* webpackChunkName: "update-check-list" */ '@/views/Instrumentation/UpdateCheckList.vue'),
    meta: { auth: true },
  },
  {
    path: '/plan/:id',
    name: 'Plan',
    component: () => import(/* webpackChunkName: "plan" */ '@/views/Plan.vue'),
    meta: { auth: true },
  },
  {
    path: '/staffs',
    name: 'Staffs',
    component: () => import(/* webpackChunkName: "staffs" */ '@/views/Staffs.vue'),
    meta: { auth: true },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
    meta: {
      auth: true,
      allowedRoles: [UserStatus.ADMIN],
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: { auth: true },
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/Chat.vue'),
    meta: { auth: true },
  },
  {
    path: '/history',
    name: 'NotificationsHistory',
    component: () => import(/* webpackChunkName: "notificationhistory" */ '@/views/NotificationsHistory.vue'),
    meta: { auth: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotpassword" */ '@/views/ForgotPassword.vue'),
    meta: {
      noNavbar: true,
    },
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "staffs" */ '@/views/Report.vue'),
    meta: { auth: true },
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '@/views/Error.vue'),
    meta: {
      noNavbar: true,
    },
    props: {
      error: {
        code: 'unauthorized.title',
        message: 'unauthorized.subtitle',
        description: 'unauthorized.description',
        action: 'unauthorized.go_home',
      },
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '@/views/Error.vue'),
    meta: {
      noNavbar: true,
    },
    props: {
      error: {
        code: 'notfound.title',
        message: 'notfound.subtitle',
        description: 'notfound.description',
        action: 'notfound.go_home',
      },
    },
  },
]

export default routes
