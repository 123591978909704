
import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import Notification from '@/models/entities/Notification'
import Button from '@/components/Button.vue'
import { namespace } from 'vuex-class'

const notification = namespace('NotificationModule')
const user = namespace('UserModule')

/**
 * Modal to show a notification
 *
 * @prop {Boolean} isOpen whether the modal is open or not
 * @prop {Notification} notification the notification to show
 *
 * @emits read when the notification is marked as read
 * @emits close when the modal is closed
 */
@Component({
  components: {
    Modal,
    Button,
  },
})
export default class ShowNotificationModal extends Vue {
  @Prop(Boolean) isOpen!: boolean

  @Prop() notification!: Notification

  @user.Getter
  authenticatedUserCanMarkNotificationAsRead!: boolean;

  @notification.Action
  setRead!: (notif: { id: string; read: boolean }) => Promise<void>

  readNotification() {
    this.setRead({ id: this.notification.id, read: true }).then(() => {
      this.$emit('read')
      this.$emit('close')
    })
  }
}
