import Model from '@/models/entities/Model'
import Searchable from '@/models/interfaces/Searchable'
import Listable from '@/models/interfaces/Listable'
import { Expose } from 'class-transformer'

/**
 * Defines the type of a surgery staff
 */
export enum SurgeryStaffType {
  SURGEON = 'Surgeon',
  INSTRUMENTIST = 'Instrumentist',
  ASSISTANT = 'Assistant',
  PERFUSIONIST = 'Perfusionist',
}

/**
 * Defines a surgery staff
 * A surgery staff is a person who works in the operating room.
 */
class SurgeryStaff extends Model implements Listable, Searchable {
  id?: string

  @Expose() firstname!: string

  @Expose() lastname!: string

  @Expose() picturePath!: string

  @Expose() professionalEmail!: string

  @Expose() privateEmail!: string

  @Expose() cellPhoneNumber!: string

  @Expose() otherInformations?: string

  @Expose() gloves!: string

  @Expose() underGloves!: string

  @Expose() gown!: string

  @Expose() type!: SurgeryStaffType

  @Expose() active!: boolean

  get fullname(): string {
    return `${this.lastname} ${this.firstname}`
  }

  get shortenedName(): string {
    return `${this.firstname[0]}. ${this.lastname}`
  }

  firstLetter(): string {
    return this.lastname[0]
  }

  title(): string {
    return this.lastname
  }

  subtitle(): string {
    return this.firstname
  }

  preview(): string {
    return `/api/images/${this.picturePath}`
  }

  badge(): string {
    return this.type
  }

  searchableContent(): string[] {
    return [this.firstname, this.lastname]
  }

  sortBy(): string {
    return this.lastname
  }

  public expose(): SurgeryStaff {
    delete this.id
    return this
  }
}

export default SurgeryStaff
