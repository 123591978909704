
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { BellNotificationIcon } from '@/icons'
import Notification from '@/models/entities/Notification'
import Button from '@/components/Button.vue'

const notification = namespace('NotificationModule')
const user = namespace('UserModule')

/**
 * Notification card component, displays a notification
 *
 * @prop {Notification} notification - Notification to display
 *
 * @emits read when the notification is marked as read
 * @emits show when the notification is clicked
 *
 */
@Component({
  components: {
    BellNotificationIcon,
    Button,
  },
})
export default class NotificationCard extends Vue {
  @Prop() readonly notification!: Notification

  @user.Getter
  isUserAdmin!: boolean

  @user.Getter
  authenticatedUserCanMarkNotificationAsRead!: boolean;

  readNotification() {
    this.setRead({ id: this.notification.id, read: true }).then(() => this.$emit('read'))
  }

  @Emit('show')
  showNotification() {
    return this.notification
  }

  @notification.Action
  setRead!: (notif: { id: string; read: boolean }) => Promise<void>
}
