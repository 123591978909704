
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Password input field with a button to show/hide the password
 *
 * Usable with v-model
 *
 * @prop {string} placeholder placeholder text to display when the input is empty
 * @prop {boolean} error indicate if the input has an error
 * @prop {string} labelAdditionalClasses classes to apply to the label
 * @prop {string} inputAdditionalClasses classes to apply to the input
 * @prop {string} name name of the input
 * @prop {string} label label text to display
 * @prop {string} error error message to display
 * @prop {string} tabindex tabindex of the input
 * @prop {string} rows number of rows of the textarea
 * @prop {string} value value of the input
 */
@Component
export default class TextareaInput extends Vue {
  @Prop(String) readonly value!: string
  @Prop(String) readonly name!: string
  @Prop(String) readonly label!: string
  @Prop(String) readonly placeholder!: string
  @Prop(String) readonly error!: string
  @Prop(String) readonly labelAdditionalClasses!: string
  @Prop(String) readonly inputAdditionalClasses!: string
  @Prop(Number) readonly tabindex!: number
  @Prop(Number) readonly rows!: number

  get input(): string {
    return this.value
  }

  set input(value: string) {
    this.$emit('input', value)
  }

  get labelClasses(): string {
    return `${this.labelAdditionalClasses} ${this.error ? 'text-red-500' : ''}`
  }

  get inputClasses(): string {
    return `${this.inputAdditionalClasses} ${this.error ? 'border-red-500' : 'border-elixir-gray'}`
  }
}
