/**
 * Format a value to two digits string
 * @param str value to format
 * @returns formated string of two digits
 */
const twoDigitsFormat = (str: string | number) => {
  return ('0' + str).slice(-2)
}

/**
 * Format a date to a string (dd/mm/yyyy hh:mm)
 * @param date Date to format
 * @returns formated date (dd/mm/yyyy hh:mm)
 */
const formatDateTime = (date: Date): string => {
  const d = new Date(date)
  return `${twoDigitsFormat(d.getDate())}/${twoDigitsFormat(d.getMonth() + 1)}/${d.getFullYear()}
     ${twoDigitsFormat(d.getHours())}:${twoDigitsFormat(d.getMinutes())}`
}

export { twoDigitsFormat, formatDateTime }
