
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

/**
 * Dropdown component used to display a menu when clicked
 *
 * It provides two slots:
 * - button: The button that will trigger the dropdown
 * - content: The content of the dropdown
 *
 * @prop {string} position - Position of the dropdown. Can be 'relative' or 'absolute'
 * @prop {string} align - Alignment of the dropdown. Can be 'left' or 'right'
 * @prop {string} containerClasses - Additional classes for the dropdown container
 * @prop {number} angle - Angle of the dropdown container
 * @prop {boolean} hideContentOnly - Hide only the content of the dropdown
 * @prop {boolean} disabled - Disable the dropdown
 */
@Component
export default class Dropdown extends Vue {
  @Prop({
    type: String,
    default: 'relative',
    validator: (value: string) => ['relative', 'absolute'].includes(value),
  })
  readonly position!: string

  @Prop({
    type: String,
    default: 'left',
    validator: (value: string) => ['left', 'right'].includes(value),
  })
  readonly align!: string

  @Prop(String) readonly containerClasses!: string

  @Prop(Number) readonly angle!: number

  @Prop(Boolean) readonly hideContentOnly!: boolean

  @Prop(Boolean) readonly disabled!: boolean

  open: boolean = false

  @Emit('toggle')
  toggle() {
    if (!this.disabled) {
      this.open = !this.open
      return this.open
    }
  }
}
