
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * A button component
 *
 * It provides two slots:
 * - iconLeft: An icon to display on the left of the label
 * - iconRight: An icon to display on the right of the label
 *
 * @prop {boolean} disabled - Disable the button
 * @prop {string} color - Color of the button. Use a tailwind color class
 * @prop {string} size - Size of the button. Can be 'small', 'normal' or 'big'. Default is 'normal'
 * @prop {string} label - Label of the button
 * @prop {string} extraClasses - Extra classes to add to the button
 *
 * @emits clicked - When the button is clicked
 */
@Component
export default class Button extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly disabled!: boolean

  @Prop({
    type: String,
    default: 'black',
  })
  readonly color!: string

  @Prop({
    type: String,
    default: 'normal',
    validator: (size: string) => ['small', 'normal', 'big'].includes(size),
  })
  readonly size!: string

  @Prop(String) readonly label!: string

  @Prop(String) readonly extraClasses!: string

  get classes() {
    let classes = `${this.extraClasses} text-${this.color} border-${this.color} `
    switch (this.size) {
      case 'small':
        classes += 'py-1 px-2 text-sm border'
        break
      case 'normal':
        classes += 'py-1 px-4 border-2'
        break
      case 'big':
        classes += 'py-2 px-6 border-2'
        break
    }

    if (this.disabled) {
      return (classes += ` cursor-not-allowed opacity-50`)
    }

    return `${classes} hover:bg-${this.color} hover:text-white`
  }

  clicked() {
    if (!this.disabled) {
      this.$emit('clicked')
    }
  }
}
