import Vue from 'vue'
import { config, VuexModule, Module, Action, Mutation, MutationAction } from 'vuex-module-decorators'
import OperationSheet from '@/models/entities/OperationSheet'
import Speciality from '@/models/entities/Speciality'
import Surgeon from '@/models/entities/Person/Surgeon'
import MultipleSearcher from '@/models/ressourcesSearch/MultipleSearcher'
import RessourceSearch from '@/models/ressourcesSearch/RessourceSearch'
import Searchable from '@/models/interfaces/Searchable'

import { request, exposableRequest, axios } from '@/plugins/axios'
import SpecialityRessourceSearch from '@/models/ressourcesSearch/SpecialityRessourceSearch'
import DoctorRessourceSearch from '@/models/ressourcesSearch/DoctorRessourceSearch'
import OperationSheetRessourceSearch from '@/models/ressourcesSearch/OperationSheetSearch'

config.rawError = true

/**
 * Module for operations on operation sheets
 */
@Module({ namespaced: true })
class OperationSheetsModule extends VuexModule {
  searcher: MultipleSearcher = new MultipleSearcher()

  specialities!: Speciality[]

  doctors!: Surgeon[]

  plans!: OperationSheet[]

  static readonly NB_MOST_USED_PLANS = 3

  mostUsedPlans!: OperationSheet[]

  @Mutation
  addSearchableRessource(searchRessource: RessourceSearch<Searchable>): void {
    const index = this.searcher.researchGetIndex(searchRessource.ressourceName)

    if (index !== -1) {
      Vue.set(this.searcher.searchableRessources, index, searchRessource)
    } else {
      this.searcher.searchableRessources.push(searchRessource)
    }
  }

  @Mutation
  updateQuery(search: { ressourceName: string; query: string }): void {
    const index = this.searcher.researchGetIndex(search.ressourceName)
    this.searcher.searchableRessources[index].search = search.query
  }

  @Mutation
  updateSelectedRessource(ressource: { ressourceName: string; data: Searchable }) {
    const index = this.searcher.researchGetIndex(ressource.ressourceName)
    this.searcher.searchableRessources[index].selectedRessource = ressource.data
    this.searcher.searchableRessources[index].search = ''
  }

  get filteredRessource() {
    return (ressourceName: string) => {
      switch (ressourceName) {
        case 'speciality':
          return this.searcher.filterRessource(ressourceName)

        default:
          return this.searcher.filterRessource(ressourceName).sort((a, b) => a.sortBy().localeCompare(b.sortBy()))
      }
    }
  }

  get selectedRessource() {
    return (ressourceName: string) => {
      return this.searcher.getSelectedRessource(ressourceName)
    }
  }

  @Action
  async initSearch() {
    const specialitiesRequest = request<Speciality[]>(
      {
        method: 'get',
        url: '/api/specialities',
      },
      Speciality
    )

    const doctorsRequest = request<Surgeon[]>(
      {
        method: 'get',
        url: '/api/staffs?type=Surgeon',
      },
      Surgeon
    )

    const plansRequest = request<OperationSheet[]>(
      {
        method: 'get',
        url: '/api/operation-sheets',
      },
      OperationSheet
    )

    const [specialities, doctors, plans] = await Promise.all([specialitiesRequest, doctorsRequest, plansRequest]);

    const specialityRessourceSearch = new SpecialityRessourceSearch('speciality', specialities)
    const doctorRessourceSearch = new DoctorRessourceSearch('doctor', doctors)
    const planRessourceSearch = new OperationSheetRessourceSearch('plan', plans)

    specialityRessourceSearch.addDependentRessource(doctorRessourceSearch)
    doctorRessourceSearch.addDependentRessource(specialityRessourceSearch)
    planRessourceSearch.addDependentRessource(specialityRessourceSearch, doctorRessourceSearch)

    this.context.commit('addSearchableRessource', specialityRessourceSearch)
    this.context.commit('addSearchableRessource', doctorRessourceSearch)
    this.context.commit('addSearchableRessource', planRessourceSearch)
  }

  @Action
  updateSearch(search: { ressourceName: string; query: string }): void {
    this.context.commit('updateQuery', search)
  }

  @Action
  onRessourceChose(ressource: { ressourceName: string; data: Searchable }): void {
    this.context.commit('updateSelectedRessource', ressource)
  }

  @Action
  async createPlan(plan: OperationSheet): Promise<OperationSheet> {
    const response = await exposableRequest(
      {
        method: 'post',
        url: '/api/operation-sheets',
      },
      plan,
      OperationSheet
    )

    plan.id = response.data.id
    return plan
  }

  @Action
  async duplicatePlan(plan: any): Promise<any> {
    return axios.post(
      '/api/operation-sheets/duplicate',
      plan
    )
  }

  @Action
  async updatePlan(plan: OperationSheet) {
    await exposableRequest(
      {
        method: 'patch',
        url: `/api/operation-sheets/${plan.id}/partial`,
      },
      plan,
      OperationSheet
    )
  }

  @Action
  async deletePlan(plan: OperationSheet) {
    await request(
      {
        method: 'delete',
        url: `/api/operation-sheets/${plan.id}`,
      },
      OperationSheet
    )
  }

  @MutationAction
  async getSpecialities() {
    const specialities = await request<Speciality[]>(
      {
        method: 'get',
        url: '/api/specialities?showUnclassified=false',
      },
      Speciality
    )
    return { specialities }
  }

  @MutationAction
  async getDoctors() {
    const doctors = await request<Surgeon[]>(
      {
        method: 'get',
        url: '/api/staffs?type=Surgeon',
      },
      Surgeon
    )
    return { doctors }
  }

  @MutationAction
  async getPlans() {
    const plans = await request<OperationSheet[]>(
      {
        method: 'get',
        url: '/api/operation-sheets',
      },
      OperationSheet
    )
    return { plans }
  }

  @MutationAction
  async getMostUsedPlans() {
    const mostUsedPlans = await request<OperationSheet[]>(
      {
        method: 'get',
        url: `/api/operation-sheets?number=${OperationSheetsModule.NB_MOST_USED_PLANS}`,
      },
      OperationSheet
    )
    return { mostUsedPlans }
  }
}

export default OperationSheetsModule
