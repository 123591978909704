/**
 * Partitions an array into two arrays, one with items that match the predicate, one with items that don't
 *
 * @param arr array to partition
 * @param predicate predicate to partition array
 * @returns two arrays, first one with items that match predicate, second one with items that don't
 */
export const partition = <T>(arr: Array<T>, predicate: (item: T) => boolean): [Array<T>, Array<T>] => {
  const result: [Array<T>, Array<T>] = [[], []]
  arr.forEach((item) => (predicate(item) ? result[0].push(item) : result[1].push(item)))
  return result
}
