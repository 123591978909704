
import { Component, Prop, Vue } from 'vue-property-decorator'
import TrayCloser from '@/components/TrayCloser.vue'

/**
 * A tray is a component that slides in from the side of the screen.
 * It uses a slot to display any content.
 *
 * @prop {boolean} isOpen Whether the tray is open or not
 * @prop {boolean} full Whether the tray should take up the full height of the screen
 * @prop {boolean} fit Whether the tray should only fit the content inside it
 * @prop {string} side The side of the screen the tray should slide in from (left or right)
 * @prop {boolean} closeOnClickOutside Whether the tray should close when clicking outside of it
 * @prop {string} additionalClasses Additional classes to apply to the tray
 * @prop {boolean} keepActive Whether the tray should be kept in the DOM even when closed
 *
 * @emits close When the tray should close
 */
@Component({
  components: {
    TrayCloser,
  },
})
export default class Tray extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly full!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly fit!: boolean

  @Prop({
    type: String,
    default: 'right',
    validator: (side: string) => ['left', 'right'].includes(side),
  })
  readonly side!: string

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly closeOnClickOutside!: boolean

  @Prop(String) readonly additionalClasses!: string

  @Prop(Boolean) readonly keepActive!: boolean

  close() {
    this.$emit('close')
  }

  get applyClasses(): string {
    let classes =
      'transform fixed bg-white overflow-auto ease-in-out transition-all duration-300 z-50 rounded-lg shadow-lg border'

    if (this.side === 'right') {
      classes += ' right-0'
      classes += this.isOpen ? ' -translate-x-0' : ' translate-x-full'
    } else {
      classes += ' left-0'
      classes += this.isOpen ? ' translate-x-0' : ' -translate-x-full'
    }

    if (!this.fit) {
      classes += ' h-full'
    }

    if (this.full) {
      classes += ' top-0'
    }

    return `${classes} ${this.additionalClasses}`
  }
}
