import OldInstrumentationList from '@/models/entities/OldInstrumentationList';
import Surgeon from '@/models/entities/Person/Surgeon';
import Speciality from '@/models/entities/Speciality';
import { request } from '@/plugins/axios'
import { config, Action, VuexModule, Module } from 'vuex-module-decorators'

config.rawError = true

@Module({ namespaced: true })
export default class InstrumentationModule extends VuexModule {
  specialities: Speciality[] = [];
  surgeons: Surgeon[] = [];
  oldInstrumentationLists: OldInstrumentationList[] = [];

  @Action
  async initialize() {
    const getSpecialitiesRequest = request<Speciality[]>(
      {
        method: 'get',
        url: '/api/specialities',
      },
      Speciality
    );

    const getSurgeonsRequest = request<Surgeon[]>(
      {
        method: 'get',
        url: '/api/staffs?type=Surgeon',
      },
      Surgeon
    );

    const getOldInstrumentationListsRequest = request<OldInstrumentationList[]>(
      {
        method: 'get',
        url: '/api/old-instrumentation-lists',
      },
      OldInstrumentationList
    );


    const [specialities, surgeons, oldInstrumentationLists] = await Promise.all([getSpecialitiesRequest, getSurgeonsRequest, getOldInstrumentationListsRequest]);

    this.specialities.splice(0, this.specialities.length);
    this.specialities.push(...specialities);

    this.surgeons.splice(0, this.surgeons.length);
    this.surgeons.push(...surgeons);

    this.oldInstrumentationLists.splice(0, this.oldInstrumentationLists.length);
    this.oldInstrumentationLists.push(
      ...([...oldInstrumentationLists]
        .sort(
          (a, b) => (a.surgeryName || a.operationSheet?.name)?.localeCompare((b.surgeryName || b.operationSheet?.name) ?? '') ?? 0
        )
        .sort((a, b) => (a.surgeryName || a.operationSheet?.name)?.match(/^\d+/) || (b.surgeryName || b.operationSheet?.name)?.match(/^\d+/) ? -1 : 1)
      )
    );
  }
}
