import Model from '@/models/entities/Model'
import Searchable from '@/models/interfaces/Searchable'

/**
 * Defines the type of a surgery object
 */
export enum SurgeryObjectType {
  ACCESSORY = 'Accessory',
  CEILING_DEVICE = 'CeilingDevice',
  COLUMN_DEVICE = 'ColumnDevice',
  DEVICE = 'Device',
  DEVICE_COLUMN = 'DeviceColumn',
  EMBEDDED_DEVICE_COLUMN = 'EmbeddedDeviceColumn',
  OPERATION_TABLE = 'OperationTable',
  PATIENT = 'Patient',
  PROBE = 'Probe',
  STAFF = 'Staff',
  STAND = 'Stand',
  SURGERY_OBJECT = 'SurgeryObject', // Others Surgery objects that do not belong to any other category
  TOOLS_TABLE = 'ToolsTable',
  TPA_COMPLEX = 'TPAComplex',
}

abstract class SurgeryObject extends Model implements Searchable {
  id?: string

  name!: string

  imageTop!: string

  imageFront?: string

  type?: SurgeryObjectType

  conversionNotify!: boolean

  width!: number

  height!: number

  displaySidebarInfo!: boolean;

  readonly defaultStackingOrder!: number

  protected constructor(type: SurgeryObjectType) {
    super()
    this.type = type
  }

  searchableContent(): string[] {
    return [this.name]
  }

  sortBy(): string {
    return this.name
  }
}

export default SurgeryObject
