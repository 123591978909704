import SurgeryStaff, { SurgeryStaffType } from '@/models/entities/Person/SurgeryStaff'
import MedicalLaboratory from '@/models/entities/MedicalLaboratory'
import Speciality from '@/models/entities/Speciality'
import { Expose, Type } from 'class-transformer'

/**
 * Defines a surgeon
 * A surgeon is a person who peforms the surgery.
 */
class Surgeon extends SurgeryStaff {
  @Expose() professionalPhoneNumber!: string

  @Expose() homePhoneNumber!: string

  @Expose() secretPhoneNumber!: string

  @Expose()
  @Type(() => Speciality)
  specialities!: Speciality[]

  @Expose()
  @Type(() => MedicalLaboratory)
  medicalLaboratories!: MedicalLaboratory[]

  constructor() {
    super()
    this.type = SurgeryStaffType.SURGEON
  }

  public expose(): Surgeon {
    super.expose()

    if (this.medicalLaboratories) {
      this.medicalLaboratories = this.medicalLaboratories.filter(
        (laboratory) => laboratory.name !== '' && laboratory.phoneNumber !== ''
      )
    } else {
      this.medicalLaboratories = []
    }
    return this
  }
}

export default Surgeon
