/**
 * Import and register Portal Vue plugin
 * Portal Vue is a plugin that allows you to render DOM outside of the Vue component tree.
 * It is mainly used the render the modals components.
 *
 * @see https://v2.portal-vue.linusb.org/
 */
import Vue from 'vue'
import PortalVue from 'portal-vue'

Vue.use(PortalVue)
