import { User } from '@/models/entities/User'
import { getAuthToken } from '@/plugins/authToken'
import { Route, NavigationGuardNext } from 'vue-router'
import store from '@/store'

/**
 * Middleware for authentication
 *
 * @param to The route we are going to
 * @param from The route we are coming from
 * @param next The next method to be called
 */
export default function authMiddleware(to: Route, from: Route, next: NavigationGuardNext) {
  if (to.matched.some((record) => record.meta.auth)) {
    // Must auth
    if (!isUserLoggedIn()) {
      next({
        name: 'Login',
      })
    } else {
      // Get the current user, validate token & logoff if revoked
      getConnectedUser()
        .then((user) => {
          if (user) {
            // Check if user has sufficient rights to access the route
            if (
              to.matched.some((record) => !record.meta.allowedRoles || record.meta.allowedRoles.includes(user.status))
            ) {
              next()
            } else {
              next({
                name: 'Unauthorized',
              })
            }
          }
        })
        .catch(() => forceDisconnect())
    }
  } else {
    next()
  }
}

/**
 * Check if a user is connected
 */
export function isUserLoggedIn(): boolean {
  return getAuthToken() != null
}

/**
 * Get the connected user's data
 * An error indicates a revoked token
 */
export async function getConnectedUser(): Promise<User | null> {
  return store.dispatch('UserModule/getUser')
}

/**
 * Disconnect user
 */
export function forceDisconnect(): void {
  store.dispatch('UserModule/logout')
}
