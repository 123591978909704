/**
 * The type of a parameter.
 * It defines the nature of the data it contains,
 * how the parameter is displayed and how it behaves.
 */
export enum ParameterType {
  LINK = 'Link',
  NUMERIC = 'Numeric',
  TEXT = 'Text',
  OPTIONS = 'Options',
  SCREEN = 'Screen',
}

/**
 * A parameter is a value related to a surgery object.
 * It can be of various types and add behavior to the object.
 * It is also used to display values on "Active Zones".
 */
export default interface IParameter<T> {
  readonly label: string
  value: T
  readonly parameterType: ParameterType
  readonly activeZoneIndex?: number

  [key: string]: unknown
}

/**
 * A parameter that has multiple options as values.
 * It can be used to change the appearance of a surgery object (image & color)
 */
export interface IOption {
  readonly name: string
  readonly frontPicture?: string
  readonly topPicture?: string
  readonly color?: string
  readonly stackingOrder?: number
  readonly height?: number
  readonly width?: number
}

/**
 * A parameter that is a numerical value.
 * I can be delimited into a range and can be incremented step-by-step by a certain value.
 */
export interface INumericParameter extends IParameter<number> {
  readonly unit: string
  readonly min: number
  readonly max: number
  readonly incrementBy: number
}

/**
 * A parameter that can either be an Option or contain sub-options.
 */
export interface IOptionsParameter extends IParameter<number> {
  readonly options: Array<IOption | IOptionsParameter>
}

/**
 * A parameter that represents a page on a screen.
 */
export interface IPage {
  readonly label: string
  readonly pageIndex: number
  readonly parameters: Array<IParameter<unknown>>
}

/**
 * A parameter that represents a screen, which is a collection of pages.
 */
export interface IScreenParameter extends IParameter<number> {
  readonly pages: Array<IPage>
  readonly screenIndex: number
}
