import { render, staticRenderFns } from "./ShowNotificationModal.vue?vue&type=template&id=741f92db&"
import script from "./ShowNotificationModal.vue?vue&type=script&lang=ts&"
export * from "./ShowNotificationModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports