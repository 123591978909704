
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

/**
 * Simple input with a label that can display an error message
 *
 * Usable with v-model
 *
 * @prop {string} placeholder placeholder text to display when the input is empty
 * @prop {boolean} error indicate if the input has an error
 * @prop {string} labelAdditionalClasses classes to apply to the label
 * @prop {string} inputAdditionalClasses classes to apply to the input
 * @prop {string} name name of the input
 * @prop {string} type type of the input (html input types)
 * @prop {string} label label text to display
 * @prop {string} error error message to display
 * @prop {string} tabindex tabindex of the input
 */
@Component
export default class SimpleInput extends Vue {
  @Prop(String) readonly value!: string

  @Prop(String) readonly name!: string

  @Prop(String) readonly label!: string

  @Prop(String) readonly placeholder!: string

  @Prop(String) readonly error!: string

  @Prop(String) readonly labelAdditionalClasses!: string

  @Prop(String) readonly inputAdditionalClasses!: string

  @Prop({
    type: String,
    default: 'text',
    validator: (value: string) =>
      ['text', 'password', 'email', 'number', 'tel', 'color', 'hidden', 'search', 'url'].includes(value),
  })
  readonly type!: string

  @Prop(Number) readonly tabindex!: number

  @Ref('field') readonly field!: HTMLInputElement

  get input(): string {
    return this.value
  }

  set input(value: string) {
    this.$emit('input', value)
  }

  get labelClasses(): string {
    return `${this.labelAdditionalClasses} ${this.error ? 'text-red-500' : ''}`
  }

  get inputClasses(): string {
    return `${this.inputAdditionalClasses} ${this.error ? 'border-red-500' : 'border-elixir-gray'}`
  }

  focus() {
    this.field.focus()
  }
}
