import IParameter, { IScreenParameter, ParameterType } from '@/models/interfaces/sheets/IParameter'
import { IOptionsParameter } from '@/models/interfaces/sheets/IParameter'

/**
 * Convert an url to a base64 string
 * @param url url to convert
 * @returns base64 string
 */
export const urlToBase64 = async (url: string): Promise<string> => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const res = reader.result
      if (res) {
        resolve(res.toString())
      }
      resolve('')
    }
  })
}

/**
 * Build the active zones of a device screen image
 * @param parent the parent element of the screen image
 * @param parameters the parameters of the device
 * @param displayLabels whether to display the labels of the parameters
 */
export const buildActiveZones = (
  parent: SVGElement,
  parameters: IParameter<unknown>[],
  displayLabels: boolean = false
): void => {
  if (!parent.querySelector) {
    return
  }

  const activeSection = parent.querySelector('#active-section')

  if (!activeSection) {
    return
  }

  const applyValue = (zone: SVGElement, key: string) => {
    const id = zone.dataset.activeIndex
    const param = parameters.find((p) => p.activeZoneIndex == id)

    if (param) {
      let value = ''

      if (param.parameterType === ParameterType.OPTIONS) {
        const index = param[key] as number
        const p = (param as IOptionsParameter).options[index] as IOptionsParameter

        if (p.options) {
          value = p.options[p.value].name as string
        } else {
          value = p.name as string
        }
      } else {
        value = param[key] as string
      }

      if (value !== '-') {
        zone.innerHTML = value
      }
    }
  }

  const applyScreen = (zone: SVGElement, key: string, screenId: string, pageId: string) => {
    const id = zone.dataset.activeIndex
    const screen = parameters.find((p) => p.screenIndex == screenId) as IScreenParameter
    if (!screen) {
      return
    }

    const activePage = screen.pages.find((p) => p.pageIndex === screen.value && p.pageIndex === parseInt(pageId))
    if (!activePage) {
      zone.innerHTML = ''
      return
    }

    const param = activePage.parameters.find((p) => p.activeZoneIndex == id)
    if (!param) {
      return
    }

    let value = ''
    if (param.parameterType === ParameterType.OPTIONS) {
      const index = param[key] as number
      const p = (param as IOptionsParameter).options[index] as IOptionsParameter

      if (p.options) {
        value = p.options[p.value].name as string
      } else {
        value = p.name as string
      }
    } else {
      value = param[key] as string
    }

    if (value !== '-') {
      zone.innerHTML = value
    }
  }

  const activeZones = activeSection.children

  for (const zone of activeZones) {
    const z = zone as SVGElement
    const className = zone.className as unknown as SVGAnimatedString

    if (z.dataset.screenIndex != undefined) {
      applyScreen(zone as SVGElement, 'value', z.dataset.screenIndex, z.dataset.pageIndex!)
    }
    // Value
    else if (className.baseVal.includes('active-zone-value')) {
      applyValue(zone as SVGElement, 'value')
    }

    if (!displayLabels) {
      continue
    }

    // Label
    if (className.baseVal.includes('active-zone-label')) {
      applyValue(zone as SVGElement, 'label')
    }
  }
}

/**
 * Add a raw SVG string into a slot as an image
 * @param x x coordinate of the slot
 * @param y y coordinate of the slot
 * @param width width of the slot
 * @param height height of the slot
 * @param raw raw svg string
 * @param classAttribute class attribute of the slot
 * @returns the slot image
 */
export const buildSlotImageFromSVGString = (
  x: string,
  y: string,
  width: string,
  height: string,
  raw: string,
  classAttribute: string
): SVGElement => {
  const doc = new DOMParser().parseFromString(raw, 'image/svg+xml')
  const slot = doc!.firstChild as SVGElement
  slot.setAttribute('width', width)
  slot.setAttribute('height', height)
  slot.setAttribute('x', x)
  slot.setAttribute('y', y)
  slot.setAttribute('class', classAttribute)
  return slot
}

/**
 * Add a base64 image into a slot as an image
 * @param x x coordinate of the slot
 * @param y y coordinate of the slot
 * @param width width of the slot
 * @param height height of the slot
 * @param base64 base64 string
 * @param classAttribute class attribute of the slot
 * @returns the slot image
 */
export const buildSlotImageFromBase64 = (
  x: string,
  y: string,
  width: string,
  height: string,
  base64: string,
  classAttribute: string
): SVGImageElement => {
  const slot = document.createElementNS('http://www.w3.org/2000/svg', 'image')
  slot.setAttribute('href', base64)
  slot.setAttribute('width', width)
  slot.setAttribute('height', height)
  slot.setAttribute('x', x)
  slot.setAttribute('y', y)
  slot.setAttribute('preserveAspectRatio', 'xMidYMid slice')
  slot.setAttribute('class', classAttribute)
  return slot
}

/**
 * Create a badge related to a svg element
 *
 * @param x x coordinate of the slot
 * @param y y coordinate of the slot
 * @param offset wished offset of the badge
 * @param classAttribute class attribute of the badge
 * @returns the SVG badge
 */
export const buildConversionBadge = (
  x: string,
  y: string,
  offset: number,
  classAttribute: string
): SVGCircleElement => {
  const badge = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
  badge.setAttribute('r', '20')
  badge.setAttribute('fill', '#F47174')
  badge.setAttribute('cx', x + offset)
  badge.setAttribute('cy', y + offset)
  badge.setAttribute('class', classAttribute)
  return badge
}
