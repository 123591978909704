/**
 * Base class for all models.
 */
abstract class Model {
  /**
   * Allows the access to the model's attributes using the bracket notation.
   */
  [key: string]: unknown

  /**
   * Perform custom behaviour on the model before sending it through an exposableRequest.
   *
   * @see plugins/axios/exposableRequest()
   * @notice This is needed because class-transformer does not support the Exclude decorator
   *         with inherited classes. Simply override this method on inherited classes that cannot use this decorator and manually apply
   *         the custom behaviour on it.
   */
  public expose(): Model {
    return this
  }
}

export default Model
