
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const pictures = namespace('PicturesCacheModule')

/**
 * A component that displays an image, relying on the pictures cache / authentication.
 * It can be lazy loaded, and can be forced to reload.
 *
 * @prop {string} src - The source of the image
 * @prop {string} alt - The alt text of the image
 * @prop {string} classes - Extra classes to add to the image
 * @prop {boolean} lazy - Whether the image should be lazy loaded. Default is true
 * @prop {string} loadingBackground - The background color (tailwind) to use while the image is loading. Default is 'bg-transparent'
 * @prop {boolean} animateLoading - Whether the loading background should be animated. Default is true
 * @prop {boolean} raw - Whether the image should be displayed as raw SVG. Default is false
 * @prop {boolean} forceReload - Whether the image should be forced to reload. Default is false
 * @prop {number} rawWidth - The width of the image when displayed as raw SVG.
 * @prop {number} rawHeight - The height of the image when displayed as raw SVG.
 * @prop {boolean} matchParent - Whether the image should match the parent size when displayed as raw SVG. Default is false
 *
 * @emits loaded - When the image is loaded
 */
@Component
export default class AuthImg extends Vue {
  @Prop(String) readonly src!: string

  @Prop(String) readonly alt!: string

  @Prop(String) readonly classes!: string

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly lazy!: boolean

  @Prop({
    type: String,
    default: 'bg-transparent',
  })
  readonly loadingBackground!: string

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly animateLoading!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly raw!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly forceReload!: boolean

  @Prop(Number) readonly rawWidth!: number

  @Prop(Number) readonly rawHeight!: number

  @Prop(Boolean) readonly matchParent!: boolean

  @Ref('img') readonly img!: HTMLImageElement

  processedImg: string = ''

  loading: boolean = false

  get customClasses() {
    return this.classes
      ? this.classes + (this.animateLoading && this.loading ? ` ${this.loadingBackground} animate-pulse` : '')
      : ''
  }

  created() {
    this.updateImage()
  }

  @Watch('src')
  updateImage() {
    this.processImage().then(() => {
      this.applyDimensions()
    })
  }

  async processImage() {
    this.loading = true
    this.processedImg = await this.getPicture({ url: this.src, raw: this.raw, forceRefresh: this.forceReload })
    this.loading = false
    this.$emit('loaded')
  }

  @Watch('rawWidth')
  @Watch('rawHeight')
  applyDimensions() {
    if (!this.raw || !this.img) {
      return
    }

    const child = this.img.firstChild as HTMLDivElement
    if (!child) {
      return
    }

    if (child.style) {
      if (this.matchParent) {
        child.style.width = `100%`
        child.style.height = `100%`
        return
      }

      child.style.width = `${this.rawWidth}px`
      child.style.height = `${this.rawHeight}px`
    }
  }

  @pictures.Action
  getPicture!: (config: { url: string; raw: boolean; forceRefresh: boolean }) => Promise<string>
}
