import Model from '@/models/entities/Model'
import Surgeon from '@/models/entities/Person/Surgeon'
import Searchable from '@/models/interfaces/Searchable'
import Speciality from '@/models/entities/Speciality'
import { Expose, Exclude } from 'class-transformer'
import OperationSheet from './OperationSheet'

export default class OldInstrumentationList extends Model implements Searchable {
   @Exclude({ toPlainOnly: true }) id!: string

   @Expose() path: string = ''

   @Expose() url: string = ''

   @Expose() surgeryName?: string;
   @Expose() speciality?: Speciality;
   @Expose() surgeon?: Surgeon;

   @Expose() operationSheet?: OperationSheet;

   searchableContent(): string[] {
      return [this.surgeryName ?? '', this.operationSheet?.name ?? '']
   }

   sortBy(): string {
      return ((this.surgeryName ?? '') || (this.operationSheet?.name ?? ''));
   }
}
