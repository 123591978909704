import FlashMessage from '@/components/FlashMessage.vue'
import { VueConstructor } from 'vue'

// Augment Vue with the flashMessage plugin
declare module 'vue/types/vue' {
  interface Vue {
    /**
     * Flash a success message
     *
     * @param title Title of the message
     * @param message Content of the message
     * @param timeout Timeout before the message is removed
     */
    $flashSuccess(title: string, message: string, timeout?: number): void

    /**
     * Flash a warning message
     *
     * @param title Title of the message
     * @param message Content of the message
     * @param timeout Timeout before the message is removed
     */
    $flashWarning(title: string, message: string, timeout?: number): void

    /**
     * Flash an error message
     *
     * @param title Title of the message
     * @param message Content of the message
     * @param timeout Timeout before the message is removed
     */
    $flashError(title: string, message: string, timeout?: number): void
  }
}

/**
 * Define types of flash messages
 */
enum FlashMessageType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

/**
 * Flash message plugin, a notification system
 */
const FlashMessagePlugin = {
  install: (Vue: VueConstructor) => {
    (Vue.prototype.$flashSuccess = function (title: string, message: string, timeout: number = 3000) {
      const instance = flash(Vue, title, message, FlashMessageType.Success, timeout)
      this.$root.$el.appendChild(instance.$el)
    }),
      (Vue.prototype.$flashWarning = function (title: string, message: string, timeout: number = 3000) {
        // Create a new flash message
        const instance = flash(Vue, title, message, FlashMessageType.Warning, timeout)
        this.$root.$el.appendChild(instance.$el)
      }),
      (Vue.prototype.$flashError = function (title: string, message: string, timeout: number = 3000) {
        // Create a new flash message
        const instance = flash(Vue, title, message, FlashMessageType.Error, timeout)
        this.$root.$el.appendChild(instance.$el)
      })
  },
}

function flash(Vue: VueConstructor, title: string, message: string, type: FlashMessageType, timeout: number) {
  // Create & mount a new flash message
  const ComponentClass = Vue.extend(FlashMessage)
  const instance = new ComponentClass({
    propsData: {
      title: title,
      content: message,
      type: type,
    },
  }).$mount()

  // Remove the message after timeout
  setTimeout(() => {
    instance.$el.remove()
  }, timeout)

  return instance
}

export default FlashMessagePlugin
