/**
 * Defines a point in 2D space
 */
export type Coordinate = {
  x: number
  y: number
}

/**
 * Defines an object with a coordinate and a size
 */
type CoordinatedObject = Coordinate & {
  width: number
  height: number
}

/**
 * Get the Axis-aligned minimum bounding box from an array of objects
 * The axis-aligned minimum bounding box (or AABB) for a given point set is its minimum bounding box
 * subject to the constraint that the edges of the box are parallel to the (Cartesian) coordinate axes.
 */
export const getAABBFromObjects = (objects: CoordinatedObject[], padding: number = 0): CoordinatedObject => {
  const minX = Math.min(...objects.map((o) => o.x))
  const minY = Math.min(...objects.map((o) => o.y))
  const maxX = Math.max(...objects.map((o) => o.x + o.width + padding))
  const maxY = Math.max(...objects.map((o) => o.y + o.height + padding))

  return {
    x: minX - padding,
    y: minY - padding,
    width: maxX - minX + padding,
    height: maxY - minY + padding,
  }
}

/**
 * Get the relative offsets of a children in its parent
 *
 * @param parentId id of the parent HTML element
 * @param childId id of the child HTML element
 *
 * @throws Error if parent or child not found, or invalid selector
 */
export const getRelativeOffsets = (parentId: string, childId: string) => {
  const parent = document.getElementById(parentId)
  const child = document.getElementById(childId)

  if (!parent || !child) {
    throw new Error('Parent or child not found')
  }

  const parentPos = parent.getBoundingClientRect()
  const childPos = child.getBoundingClientRect()

  return {
    top: childPos.top - parentPos.top,
    right: childPos.right - parentPos.right,
    left: childPos.left - parentPos.left,
    bottom: childPos.bottom - parentPos.bottom,
  }
}

/**
 * Convert pixels to percentage of total pixels
 * Useful to convert position of objects on the plan to percentage, keeping the same ratio on different screen sizes
 *
 * @param pixels pixels to convert to percentage
 * @param totalPixels total pixels to use as reference
 * @returns percentage
 */
export const percentageFromPixels = (pixels: number, totalPixels: number) => {
  return (pixels / totalPixels) * 100
}

/**
 * Convert percentage to pixels of total pixels
 *
 * @param percentage percentage to convert to pixels
 * @param totalPixels total pixels to use as reference
 * @returns pixels
 */
export const pixelsFromPercentage = (percentage: number, totalPixels: number) => {
  return (percentage / 100) * totalPixels
}
