
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import NotificationCard from '@/components/Notification/NotificationCard.vue'
import ShowNotificationModal from '@/components/Notification/Modals/ShowNotificationModal.vue'
import Notification from '@/models/entities/Notification'
import CreateNotificationModal from '@/components/Notification/Modals/CreateNotificationModal.vue'
import Tray from '@/components/Tray.vue'
import Button from '@/components/Button.vue'

const notification = namespace('NotificationModule')

/**
 * Displays the notification tray
 */
@Component({
  components: {
    Tray,
    NotificationCard,
    ShowNotificationModal,
    CreateNotificationModal,
    Button,
  },
})
export default class NotificationTray extends Vue {
  isShowModalOpen: boolean = false

  isCreateModalOpen: boolean = false

  toShowNotif: Notification = new Notification()

  @notification.Getter
  unreadNotifications!: Notification[]

  @notification.State
  isNotificationTrayOpen!: boolean

  showNotification(notif: Notification) {
    this.toShowNotif = notif
    this.isShowModalOpen = true
  }

  clearShowModal() {
    this.isShowModalOpen = false
    this.toShowNotif = new Notification()
  }

  linkToHistory() {
    this.toggleNotificationTray()
    this.$router.push({ name: 'NotificationsHistory' })
  }

  created() {
    this.getUnreadNotification()
  }

  @notification.Action
  getUnreadNotification!: () => void

  @notification.Action
  toggleNotificationTray!: () => void
}
