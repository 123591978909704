export class Message {
  constructor(
    public readonly id: string,
    public readonly body: string,
    public readonly senderId: string,
    public readonly receiverId: string,
    public readonly createdAt: Date,
    public readonly readAt: Date | null = null,
  ) {}

  public static createFromObject(object: Record<string, any>) {
    return new Message(
      object.id,
      object.body,
      object.senderId,
      object.receiverId,
      new Date(object.createdAt),
      object.readAt ? new Date(object.readAt) : null,
    )
  }
}