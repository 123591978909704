
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * A component that closes the tray when clicked, act as a click-outside handler.
 *
 * @prop {Function} close The function to call when the tray should be closed
 * @prop {boolean} isOpen Whether the tray is open or not
 */
@Component
export default class TrayCloser extends Vue {
  @Prop(Function) readonly close!: () => void

  @Prop(Boolean) readonly isOpen!: boolean
}
