import { axios } from '@/plugins/axios'
import { config, Action, VuexModule, Module } from 'vuex-module-decorators'

config.rawError = true

@Module({ namespaced: true })
export default class InstrumentDataModule extends VuexModule {
  private _specialities: string[] = [];
  private _lieu: string[] = [];
  private _alley: string[] = [];

  get instrumentSpecialities() {
    return this._specialities;
  }

  get instrumentLocationLieu() {
    return this._lieu;
  }

  get instrumentLocationAlley() {
    return this._alley;
  }

  @Action
  async initializeInstrumentData() {
    const getInstrumentsSpecialitiesRequest = axios.get('/api/instrument-data/specialities');
    const getInstrumentsLocationLieuRequest = axios.get('/api/instrument-data/location/lieu');
    const getInstrumentsLocationAlleyRequest = axios.get('/api/instrument-data/location/alley');


    const [instrumentsSpecialities, instrumentsLocationLieu, instrumentsLocationAlley] = await Promise.all([getInstrumentsSpecialitiesRequest, getInstrumentsLocationLieuRequest, getInstrumentsLocationAlleyRequest]);

    this._specialities.splice(0, this._specialities.length);
    this._specialities.push(...instrumentsSpecialities.data);

    this._lieu.splice(0, this._lieu.length);
    this._lieu.push(...instrumentsLocationLieu.data);

    this._alley.splice(0, this._alley.length);
    this._alley.push(...instrumentsLocationAlley.data);
  }
}
