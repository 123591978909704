import { config, VuexModule, Module, MutationAction, Action } from 'vuex-module-decorators'
import { User } from '@/models/entities/User'
import { exposableRequest, exposableArrayRequest, request, axios } from '@/plugins/axios'
import RessourceSearch from '@/models/ressourcesSearch/RessourceSearch'

config.rawError = true

/**
 * Module for operations on global users
 */
@Module({ namespaced: true })
class UsersModule extends VuexModule {
  searcher: RessourceSearch<User> = new RessourceSearch('users', [])

  functions!: string[]

  statuses!: string[]

  get allUsers() {
    return this.searcher.ressources;
  }

  get usersCount(): number {
    return this.searcher.ressources ? this.searcher.ressources.length : 0
  }

  get filteredUsers(): User[] {
    const searchTerms = this.searcher.search.split(' ')

    const users = this.searcher.ressources
      ? this.searcher.ressources.filter((user) =>
          user.searchableContent().some((filter) => searchTerms.some((term) => filter.toLowerCase().includes(term)))
        )
      : []

    return users.sort((a, b) => a.lastname.localeCompare(b.lastname))
  }

  get filteredUsersCount(): number {
    return this.filteredUsers.length
  }

  get letterGroupedUsers() {
    const groups: { [key: string]: User[] } = {}

    this.filteredUsers.forEach((user: User) => {
      groups[user.firstLetter()] ??= []
      groups[user.firstLetter()].push(user)
    })
    return groups
  }

  /**
   * Get all users
   * @returns the list of users
   */
  @MutationAction
  async getUsers() {
    const users = await request<User[]>(
      {
        method: 'get',
        url: '/api/users',
      },
      User
    )
    return { searcher: new RessourceSearch('users', users) }
  }

  /**
   * Create a new user
   * @param user user to create
   * @returns the created user
   */
  @Action
  async createUser(user: User): Promise<User> {
    const response = await exposableRequest(
      {
        method: 'post',
        url: '/api/users',
      },
      user,
      User
    )
    user.id = response.data.id
    return user
  }

  /**
   * Create a new user with its associated medical staff
   * @param user user to create
   * @returns the created user
   */
  @Action
  async createUserAndMedicalStaff(user: User): Promise<User> {
    const response = await exposableRequest(
      {
        method: 'post',
        url: '/api/users/user-and-medical-staff',
      },
      user,
      User
    )
    user.id = response.data.id
    return user
  }

  /**
   * Create users from an array.
   * Useful for mass import (CSV)
   * @param users users to create
   */
  @Action
  async createUsers(users: User[]): Promise<void> {
    await exposableArrayRequest(
      {
        method: 'post',
        url: '/api/users/array',
      },
      users,
      User
    )
  }

  /**
   * Update a user
   * @param user user to update
   * @returns the updated user
   */
  @Action
  async updateUser(user: User): Promise<User> {
    await exposableRequest(
      {
        method: 'patch',
        url: `/api/users/${user.id}`,
      },
      user,
      User
    )
    return user
  }

  /**
   * Update a user's picture
   * @param data user and picture to update
   */
  @Action
  async updatePicture(data: { user: User; picture: File }): Promise<void> {
    const file = new FormData()
    file.append('image', data.picture)

    await axios.post(`/api/images/user/${data.user.id}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  /**
   * Get a user by its id
   * @param id id of the user to get
   * @returns the user
   */
  @Action
  async getUserById(id: string): Promise<User> {
    return await request<User>(
      {
        method: 'get',
        url: `/api/users/${id}`,
      },
      User
    )
  }

  /**
   * Get the list of avaible functions for a user
   * A function is the role of the user in the hospital
   * @returns the list of functions
   */
  @MutationAction
  async getFunctions() {
    const functions = await request<string[]>(
      {
        method: 'get',
        url: '/api/enums/functions',
      },
      String
    )

    return { functions }
  }

  /**
   * Get the list of avaible statuses for a user
   * A status is the role of the user in the application
   * @returns the list of statuses
   */
  @MutationAction
  async getStatuses() {
    const statuses = await request<string[]>(
      {
        method: 'get',
        url: '/api/enums/status',
      },
      String
    )

    return { statuses }
  }

  /**
   * Apply a search query on the users
   * @param s search query
   */
  @MutationAction
  async applySearch(s: string) {
    return {
      searcher: {
        ...this.searcher,
        search: s.toLowerCase(),
      },
    }
  }
}

export default UsersModule
