/**
 * Augments the declaration of Document to handle fullscreen compatibility in various browsers
 */
type ExitFullscreen = typeof document.exitFullscreen
type RequestFullscreen = typeof document.documentElement.requestFullscreen

declare global {
  interface Document {
    webkitExitFullscreen: ExitFullscreen // Safari
    mozCancelFullScreen: ExitFullscreen // Firefox
    msExitFullscreen: ExitFullscreen // IE
  }

  interface HTMLElement {
    webkitRequestFullscreen: RequestFullscreen // Safari
    mozRequestFullScreen: RequestFullscreen // Firefox
    msRequestFullscreen: RequestFullscreen // IE
  }
}

/**
 * Enable browser window fullscreen
 */
export const openFullScreen = () => {
  const elem = document.documentElement
  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen()
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen()
  }
}

/**
 * Disable browser window fullscreen
 */
export const closeFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
}
