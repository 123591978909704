import { config, VuexModule, Module, Action, MutationAction } from 'vuex-module-decorators'
import Notification from '@/models/entities/Notification'
import { axios, request, exposableRequest } from '@/plugins/axios'
import RessourceSearch from '@/models/ressourcesSearch/RessourceSearch'

config.rawError = true

/**
 * Module for operations on notifications
 */
@Module({ namespaced: true })
class NotificationModule extends VuexModule {
  readonly HISTORY_DAYS: number = 1_000_000;

  isNotificationTrayOpen: boolean = false

  searcher: RessourceSearch<Notification> = new RessourceSearch('notifications', [])

  searcherHistory: RessourceSearch<Notification> = new RessourceSearch('notificationsHistory', [])

  get unreadNotifications(): Notification[] {
    return this.searcher.ressources
  }

  get unreadNotificationsCount(): number {
    return this.searcher.ressources.length
  }

  get unreadNotificationsCountForToday(): number {
    return this.searcher.ressources.filter((notification) => new Date(notification.creationDate).toDateString() === (new Date()).toDateString()).length
  }

  get filteredNotifications(): Notification[] {
    const searchTerms = this.searcher.search.split(' ')

    const notifications = this.searcher.ressources
      ? this.searcher.ressources.filter((notif) =>
          notif.searchableContent().some((filter) => searchTerms.some((term) => filter.toLowerCase().includes(term)))
        )
      : []

    return notifications
  }

  get filteredHistoryNotifications(): Notification[] {
    const searchTerms = this.searcherHistory.search.split(' ')

    const notifications = this.searcherHistory.ressources
      ? this.searcherHistory.ressources.filter((notif) =>
          notif.searchableContent().some((filter) => searchTerms.some((term) => filter.toLowerCase().includes(term)))
        )
      : []

    return notifications
  }

  @Action
  async setRead(notif: { id: string; read: boolean }): Promise<void> {
    if (notif.read) {
      await axios.patch(`/api/notifications/markAsRead/${notif.id}`)
    } else {
      await axios.patch(`/api/notifications/restore/${notif.id}`)
    }
  }

  @Action
  async create(notification: Notification): Promise<void> {
    await exposableRequest(
      {
        method: 'post',
        url: '/api/notifications',
      },
      notification,
      Notification
    )
  }

  @MutationAction
  async toggleNotificationTray() {
    return { isNotificationTrayOpen: !this.isNotificationTrayOpen }
  }

  @MutationAction
  async applySearch(s: string) {
    const search = s.toLowerCase()
    return {
      searcher: {
        ...this.searcher,
        search,
      },
      searcherHistory: {
        ...this.searcherHistory,
        search,
      },
    }
  }

  @MutationAction
  async getUnreadNotification() {
    const notifications = await request<Notification[]>(
      {
        method: 'get',
        url: '/api/notifications',
      },
      Notification
    )

    return {
      searcher: {
        ...this.searcher,
        ressources: notifications,
      },
    }
  }

  @MutationAction
  async getHistoryNotification() {
    const historyNotifications = await request<Notification[]>(
      {
        method: 'get',
        url: '/api/notifications/historic?numberOfDays=' + this.HISTORY_DAYS,
      },
      Notification
    )

    return {
      searcherHistory: {
        ...this.searcher,
        ressources: historyNotifications,
      },
    }
  }
}

export default NotificationModule
