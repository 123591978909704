import OperationSheet from '@/models/entities/OperationSheet'
import CustomRessourceSearch from '@/models/ressourcesSearch/CustomRessourceSearch'
import SpecialityRessourceSearch from './SpecialityRessourceSearch'
import DoctorRessourceSearch from '@/models/ressourcesSearch/DoctorRessourceSearch'
import Searchable from '../interfaces/Searchable'

/**
 * Custom Ressource Search for OperationSheet
 */
class OperationSheetRessourceSearch extends CustomRessourceSearch<OperationSheet, Searchable> {
   /**
    * Filter the operation sheets by selected speciality and surgeon
    * @returns filtered operation sheets
    */
   filter(): OperationSheet[] {
      let filtered = super.filter()

      if (!this.dependOn || this.dependOn.length !== 2) {
         return filtered
      }

      const speciality = this.dependOn[0].selectedRessource
      const surgeon = this.dependOn[1].selectedRessource

      if (speciality) {
         filtered = filtered.filter((operationSheet: OperationSheet) => {
            return operationSheet.speciality?.id === speciality?.id
         })
      }

      if (surgeon) {
         filtered = filtered.filter((operationSheet: OperationSheet) => {
            return operationSheet.surgeon?.id === surgeon?.id
         })
      }

      return filtered
   }

   /**
    * Add dependencies to speciality and surgeon ressources
    * @param spec SpecialityRessourceSearch to depend on
    * @param doc DoctorRessourceSearch to depend on
    */
   addDependentRessource(spec: SpecialityRessourceSearch, doc: DoctorRessourceSearch) {
      super.addDependentRessource(spec, doc)
   }
}

export default OperationSheetRessourceSearch
