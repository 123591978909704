import Vue from 'vue'
import Vuex from 'vuex'
import UserModule from './modules/user'
import UsersModule from './modules/users'
import StaffsModule from './modules/staffs'
import OperationSheetModule from './modules/operationSheet'
import OperationSheetsModule from './modules/operationSheets'
import NotificationModule from './modules/notification'
import ChatModule from './modules/chat'
import PicturesCacheModule from './modules/picturesCache'
import InstrumentationModule from './modules/instrumentation'
import InstrumentDataModule from './modules/instrument-data'

Vue.use(Vuex)

/**
 * The is the entry point for the store.
 * The namespaced modules are registered here.
 */
export default new Vuex.Store({
  modules: {
    UserModule,
    UsersModule,
    StaffsModule,
    OperationSheetModule,
    OperationSheetsModule,
    NotificationModule,
    ChatModule,
    PicturesCacheModule,
    InstrumentationModule,
    InstrumentDataModule,
  },
})
