import Vue from 'vue'
import 'reflect-metadata'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { axios } from '@/plugins/axios'
import i18n from '@/plugins/i18n'
import VueLazyload from 'vue-lazyload'
import '@/plugins/portal'

// Load styles
import '@/assets/proxima-nova.css'
import '@/assets/tailwind.css'
import '@/assets/multiselect.css'
import 'flowbite'

import FlashMessagePlugin from '@/plugins/flashMessage'

Vue.config.productionTip = false

// Bind axios
Vue.prototype.$http = axios

// Load plugins
Vue.use(FlashMessagePlugin)
Vue.use(VueLazyload)

// Load components and render the app
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
