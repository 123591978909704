
import { Component, Prop } from "vue-property-decorator";
import { unaccent } from "@/helpers";
import Vue from "vue";

@Component({})
export default class SearchableDropdownInput extends Vue {
  @Prop(String) placeholder!: string;
  @Prop({ default: null }) additionalClasses!: string | null;

  @Prop() dropdownItems!: string[];

  // Prop for the component `v-model`
  @Prop(String) value!: string;

  showDropdown: boolean = false;

  get filteredDropdownItems() {
    return this.dropdownItems.filter((item) =>
      unaccent(item.toLocaleLowerCase()).includes(
        unaccent(this.value.toLocaleLowerCase())
      )
    );
  }

  emitInputEvent(event: Event) {
    this.$emit("input", (event.target as HTMLInputElement).value);
  }

  onItemSelection(item: string) {
    this.$emit("input", item);
  }

  showDropdownItems() {
    this.showDropdown = true;
  }

  hideDropdownItems() {
    setTimeout(() => (this.showDropdown = false), 200);
  }
}
