import Searchable from '@/models/interfaces/Searchable'

/**
 * Wrapper for ressource searching, selection and filtering
 */
class RessourceSearch<T extends Searchable> {
   ressourceName: string
   search: string
   ressources: T[]
   selectedRessource!: T

   constructor(ressourceName: string, ressources: T[] = [], search: string = '') {
      this.ressourceName = ressourceName
      this.ressources = ressources
      this.search = search
   }

   filter(): T[] {
      const searchTerms = this.search.split(' ')

      return this.ressources.filter((resource: Searchable) => {
         return resource.searchableContent().length
            ? resource
                 .searchableContent()
                 .some((filter: string) =>
                    searchTerms.every((term) => this.normalize(filter).toLowerCase().includes(this.normalize(term).toLowerCase()))
                 )
            : resource
      })
   }

   private normalize(string: string) {
      return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
   }
}

export default RessourceSearch
