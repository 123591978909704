
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import Dropdown from '@/components/Dropdown.vue'
import { ArrowDownIcon, CancelIcon } from '@/icons'
import OperationSheet from '@/models/entities/OperationSheet'

/**
 * Dropdown to display the user's personal list of plans
 *
 * @prop {OperationSheet[]} plans - The plans to display
 *
 * @event remove - When the user removes a plan from the list
 */
@Component({
  components: {
    Dropdown,
    ArrowDownIcon,
    CancelIcon,
  },
})
export default class MyListDropdown extends Vue {
  @Prop() readonly plans!: OperationSheet[]

  @Ref('dropdown') readonly dropdown!: Dropdown

  get listSize() {
    return this.plans.length
  }

  removeFromList(plan: OperationSheet) {
    this.$emit('remove', plan)
  }

  close() {
    this.dropdown.toggle()
  }
}
