import { config, VuexModule, Module, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import { ProfileEdition, User, UserStatus } from '@/models/entities/User'
import { axios, exposableRequest, request } from '@/plugins/axios'
import { deleteAuthToken, setAuthToken } from '@/plugins/authToken'
import OperationSheet from '@/models/entities/OperationSheet'
import router from '@/router'

config.rawError = true

/**
 * Module for operation on the currently authentified user
 */
@Module({ namespaced: true })
class UserModule extends VuexModule {
  connectedUser!: User

  isUserTrayOpen: boolean = false

  myList: OperationSheet[] = []

  @Mutation
  setConnectedUser(user: User): void {
    this.connectedUser = user
  }

  @MutationAction
  async toggleUserTray() {
    return { isUserTrayOpen: !this.isUserTrayOpen }
  }

  /**
   * Check if a plan is already in the user's "my list"
   */
  get isPlanInMyList() {
    return (planId: string): boolean => {
      return this.myList.some((operationSheet) => operationSheet.id === planId)
    }
  }

  get authenticatedUserCanMarkNotificationAsRead(): boolean {
    return this.connectedUser.canMarkNotificationAsRead;
  }

  /**
   * Check if connected user has at least admin role
   */
  get isUserAdmin(): boolean {
    return this.connectedUser ? this.connectedUser.status === UserStatus.ADMIN : false
  }

  /**
   * Check if connected user has at least editor role
   */
  get isUserEditor(): boolean {
    return this.connectedUser ? [UserStatus.ADMIN, UserStatus.EDITOR].includes(this.connectedUser.status) : false
  }

  /**
   * Get the connected user's data
   *
   * In case of error (token revoked), the user is disconnected
   */
  @Action
  async getUser(): Promise<User | null> {
    try {
      const user = await request<User>(
        {
          method: 'get',
          url: '/api/users/me',
        },
        User
      )

      this.context.commit('setConnectedUser', user)
      return user
    } catch (e) {
      deleteAuthToken()
      this.context.commit('setConnectedUser', null)
      router.push({ name: 'Login' })
      return null
    }
  }

  /**
   * Connect the user with the given credentials
   *
   * @param credentials login credentials
   */
  @Action
  async login(credentials: { email: string; password: string }): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/login', { ...credentials })
        .then((response) => {
          setAuthToken(response.data.access_token)
          resolve('Login success')
        })
        .catch((err) => {
          reject(err.response)
        })
    })
  }

  /**
   * Disconnect the user.
   * The user will be redirected to the login page
   */
  @Action
  async logout() {
    axios
      .post('/api/auth/logout')
      .then(() => {
        deleteAuthToken()
        router.push({ name: 'Login' })
        this.context.commit('setConnectedUser', null)
      })
      .then(() => {
        window.location.reload()
      })
  }

  /**
   * Update the user's profile
   *
   * @param user User to update
   */
  @Action
  async updateProfile(user: User) {
    await exposableRequest(
      {
        method: 'patch',
        url: `/api/users/profile`,
      },
      ProfileEdition.fromUser(user),
      User
    )

    return user
  }

  /**
   * Ask for a password reset
   * @param email email of the user
   * @returns message to display to the user
   */
  @Action
  async resetPassword(email: string): Promise<string> {
    const response = await axios.post('/api/auth/forgotPassword', { email })
    return response.data.message
  }

  /**
   * Change the password of a user
   * @param data token and new password
   */
  @Action
  async changeForgottenPassword(data: { token: string; password: string }): Promise<void> {
    await axios.patch('/api/auth/changePassword', { token: data.token, password: data.password })
  }

  /**
   * Get the user's "my list"
   * @returns the user's "my list"
   */
  @MutationAction
  async getMyList() {
    const myList = await request<OperationSheet[]>(
      {
        method: 'get',
        url: '/api/operation-sheets-list',
      },
      OperationSheet
    )
    return { myList }
  }

  /**
   * Add a plan to the user's "my list"
   * @param operationSheet plan to add
   */
  @Action
  async addToMyList(operationSheet: OperationSheet) {
    await exposableRequest(
      {
        method: 'post',
        url: `/api/operation-sheets-list/${operationSheet.id}`,
      },
      operationSheet,
      OperationSheet
    )
  }

  /**
   * Remove a plan from the user's "my list"
   * @param operationSheet plan to remove
   */
  @Action
  async removeFromMyList(operationSheet: OperationSheet) {
    await exposableRequest(
      {
        method: 'delete',
        url: `/api/operation-sheets-list/${operationSheet.id}`,
      },
      operationSheet,
      OperationSheet
    )
  }
}

export default UserModule
