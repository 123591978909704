import Model from '@/models/entities/Model'
import { Expose } from 'class-transformer'
import { User } from '@/models/entities/User'
import { formatDateTime } from '@/helpers'
import Searchable from '@/models/interfaces/Searchable'

/**
 * Defines a standard notification
 * A notification is designed to be sent to every hospital user to inform them about any informations.
 */
class Notification extends Model implements Searchable {
  @Expose() id!: string

  @Expose() title!: string

  @Expose() description!: string

  @Expose() creationDate!: Date

  @Expose() hasRead?: boolean

  @Expose({ name: 'notificationOwner' }) owner!: User

  get formatedCreationDate() {
    return formatDateTime(this.creationDate)
  }

  searchableContent(): string[] {
    return [this.title]
  }

  sortBy(): string {
    return this.title
  }
}

export default Notification
